import { createContext, useEffect, useState, useContext } from "react";

import { ExaminationContext } from "./Examination";
import { NotificationContext } from "./Notification";
import ResourceApi from "../services/resource";
import { withTranslation } from "react-i18next";

import { MeasurementDefaultUnits } from "../config";
import Icon from "../atoms/Icon/Icon";

export const MeasurementsContext = createContext({});
const MEASUREMENTS = "_measurements";

export const MeasurementsContextProvider = withTranslation()(({ t: __, children }) => {
    const examinationContext = useContext(ExaminationContext);
    const notificationContext = useContext(NotificationContext);

    const currentLanguage = localStorage.getItem("i18nextLng").toLowerCase();
    const [labels, setLabels] = useState({});
    const [measurementData, setMeasurementData] = useState({});
    const optimisticUpdateEpisode = (attrs) =>
        setMeasurementData({ ...measurementData, episode: { ...measurementData.episode, ...attrs } });
    const [grouping, setGrouping] = useState([]);
    const [formattedGrouping, setFormattedGrouping] = useState([]);
    const [groupLabels, setGroupLabels] = useState([]);

    const examId = examinationContext.examination.id;

    const loadMeasurementsData = async () => {
        if (!examId) return;
        const response = await ResourceApi.getReportMeasurements(examId);
        setMeasurementData(response.data);
    };

    const loadLabels = async () => {
        const response = await ResourceApi.getReportMeasurementLabels(examId);
        setLabels(response.data);
        setGrouping(response.data.groups);
        setFormattedGrouping(getFormattedGrouping(response.data.groups));
        setGroupLabels(response.data.group_labels);
    };

    useEffect(() => {
        const f = async () => {
            if (!examinationContext.examination.id) return;
            await loadMeasurementsData();
            loadLabels();
        };
        f();
    }, [examinationContext.examination.preset_id]);

    useEffect(() => {
        if (!examinationContext.examination.id) return;
        loadMeasurementsData();
    }, [
        examinationContext.examination.id,
        JSON.stringify(examinationContext.instances.filter((i) => i.modality === "SR").map(({ id }) => id)),
    ]);

    const updateMeasurements = async (changes) => {
        try {
            await ResourceApi.updateMeasurements(examId, changes);
            loadMeasurementsData();
            return true;
        } catch (error) {
            notificationContext.showNotification(
                <>
                    <Icon name="warning" /> {__("report.unableToUpdate")}
                </>,
                5000
            );
            return false;
        }
    };

    const removeIdentifierMeasurementRow = async (measurement_id) => {
        try {
            await ResourceApi.deleteMeasurement(examId, measurement_id, "identifier");
            loadMeasurementsData();
            return true;
        } catch (error) {
            notificationContext.showNotification(
                <>
                    <Icon name="warning" /> {__("report.unableToUpdate")}
                </>,
                5000
            );
            return false;
        }
    };

    const getDefaultLabel = (measurementId, bodyStructureId, lateralityId) => {
        let label = labels?.measurement?.[measurementId]?.label?.[currentLanguage] || measurementId;
        const siteLabel = labels?.body_structure?.[bodyStructureId]?.label?.[currentLanguage] || bodyStructureId;
        const lateralityLabel = labels?.laterality?.[lateralityId]?.label?.[currentLanguage] || lateralityId;
        if (siteLabel) label += `, ${siteLabel}`;
        if (lateralityLabel) label += ` (${lateralityLabel})`;
        return label;
    };

    // TODO: this is a lighter version of a function by the same in XMLTemplate.
    // Investigate if they can be combined in some way
    const getMeasurementObject = (slug, fetusId) => {
        if (!slug || !fetusId) return {};

        const measurement =
            fetusId === "patient"
                ? measurementData?.measurements?.patient?.[slug]
                : measurementData?.measurements?.fetus?.[fetusId]?.[slug];
        const [measurementId, bodyStructureId, lateralityId] = slug.split(/[./]/);

        let edits = measurementData?.measurement_edits?.[fetusId]?.[slug];
        let parsedReport = labels?.parsed_template?.[slug];

        const percentiles = Object.fromEntries(
            Object.entries(measurement?.selected_value?.percentiles || {}).filter(
                ([_key, value]) => value.reference_value !== null
            )
        );

        const defaultDisplayUnit = labels?.measurement?.[measurementId]?.units;
        const displayUnit = edits?.units || parsedReport?.units || defaultDisplayUnit;
        const storedUnit = MeasurementDefaultUnits[labels?.measurement?.[measurementId]?.type];
        return {
            editedLabel: edits?.label,
            label: edits?.label || parsedReport?.label || getDefaultLabel(measurementId, bodyStructureId, lateralityId),
            value: measurement?.selected_value?.y,
            xvalue: measurement?.selected_value?.x,
            storedUnit,
            // TODO: Support multi value units
            displayUnit: displayUnit === "lbs.oz" ? "g" : displayUnit,
            decimals: parsedReport?.decimals || 1,
            comment: edits?.comment,
            visible: edits?.visible ?? parsedReport?.visible,
            curve_slug: edits?.curve_slug || measurement?.selected_value?.curve,
            percentile: measurement?.selected_value?.sonio_percentile,
            zscore: measurement?.selected_value?.sonio_zscore,
            reference_value: measurement?.selected_value?.reference_value,
            estimation: measurement?.selected_value?.estimation || false,
            derivation: measurement?.selected_value?.derivation || false,
            availableDerivations: (measurement?.all_values || []).map((val) => ({
                value: val.y,
                derivation: val.derivation,
            })),
            percentiles,
            availableCurveSlugs: Object.keys(percentiles || {}),
            estimatedGa: measurementData?.v2_dating_values?.find(
                (d) => d.estimation.startsWith(`${slug}.`) && d.fetus === `${fetusId}`
            ),
        };
    };

    const makeDerivationLabel = (derivationSlug, value) => {
        const [derivation, estimation] = derivationSlug.split(".");
        const derivationLabel = labels?.derivation?.[derivation]?.label?.[currentLanguage];
        return derivationLabel + (estimation ? ` (${estimation})` : "") + ` - ${value}`;
    };

    const setDefaultProperty = (obj, prop, defaultValue) => {
        if (!obj.hasOwnProperty(prop)) obj[prop] = defaultValue;
        return obj;
    };

    function insertIntoSortedArray(array, toInsert) {
        let index = 0;
        while (index < array.length && array[index].order < toInsert.order) {
            index++;
        }
        array.splice(index, 0, toInsert);
        return array;
    }

    // Takes array of slugs with their groups creates nested objects
    // Where the key "_measurements" exists, we have a list of measurements and subsubsection titles

    const getFormattedGrouping = (grouping) => {
        let formattedGrouping = {};
        for (const { measurement_slug, section_id, subsection_id, subsubsection_id, order } of grouping) {
            setDefaultProperty(formattedGrouping, section_id, {});
            setDefaultProperty(formattedGrouping[section_id], subsection_id, {});

            if (subsubsection_id === null) {
                setDefaultProperty(formattedGrouping[section_id][subsection_id], MEASUREMENTS, []);
                insertIntoSortedArray(formattedGrouping[section_id][subsection_id][MEASUREMENTS], {
                    slug: measurement_slug,
                    order,
                });
            } else {
                setDefaultProperty(formattedGrouping[section_id][subsection_id], subsubsection_id, {});
                setDefaultProperty(formattedGrouping[section_id][subsection_id][subsubsection_id], MEASUREMENTS, []);
                insertIntoSortedArray(formattedGrouping[section_id][subsection_id][subsubsection_id][MEASUREMENTS], {
                    slug: measurement_slug,
                    order,
                });
            }
        }
        return formattedGrouping;
    };

    const findMeasurementGroupLabel = (slug) => {
        const groupLabel = groupLabels.find((group) => group.slug === slug);
        if (!groupLabel) return {};
        // For use in Tabs
        const label = groupLabel?.label?.[currentLanguage] || groupLabel.slug;
        return { ...groupLabel, value: groupLabel.slug || "ERROR", label: label };
    };

    return (
        <MeasurementsContext.Provider
            value={{
                optimisticUpdateEpisode,
                labels,
                measurementData,
                updateMeasurements,
                loadMeasurementsData,
                measurementLabels: labels,
                getMeasurementObject,
                grouping,
                formattedGrouping,
                makeDerivationLabel,
                groupLabels,
                findMeasurementGroupLabel,
                isAssignedDatingSet: examinationContext?.examination?.dating?.assigned_dating_id,
                removeIdentifierMeasurementRow,
            }}
        >
            {children}
        </MeasurementsContext.Provider>
    );
});
export const useMeasurements = () => useContext(MeasurementsContext);
