import axios from "axios";
import PropTypes from "prop-types";
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState, useReducer } from "react";
import { withTranslation } from "react-i18next";
import { useHistory, useLocation } from 'react-router-dom';
import Button from "../atoms/Button/Button";
import Icon from "../atoms/Icon/Icon";
import LoaderInline from "../atoms/LoaderInline/LoaderInline";
import Popup from "../components/Popup/Popup";
import { presets as presetsConfig, ExamStatus } from "../config";
import DxAiApi from "../services/dx-ai";
import { createFullName } from "../services/examination";
import LookupApi from "../services/lookup";
import ResourceApi from "../services/resource";
import { getInstancePreviewUri, isNullOrUndefined, isGaInTrimester } from "../utils";
import { AppContext } from "./App";
import useAuth from "./Auth";
import { LiveExaminationContext } from "./LiveExamination";
import { LiveSessionEventContext } from "./LiveSessionEvent";
import ManualUserSwitchDialog from './ManualUserSwitchDialog';
import { NotificationContext } from "./Notification";
import ReassociationDialog from "./ReassociationDialog";
import SelectExamToStartDialog from "./SelectExamToStartDialog";
import ReviseExamPopup from "../components/ReviseExamPopup";
import loadGeneralExamAssocAttachments from "../services/loadGeneralExamAssocAttachments";
import loadExamAssocAttachments from "../services/loadExamAssocAttachments";
import Cookies from "js-cookie";

/*
 * TODO not examination context: meaning the function declared here is not part of
 * the examination context as it does not interact with the data within the context
 * These functions should be moved in another places like the `services` directory
 */

export const ExaminationContext = createContext({ examination: {}, createExamination: () => { } });

const singleExaminationDataReducer = (examinationData, data) => {
  const slug = data.slug
  const examination_fetus_id = `${data.examination_fetus_id}`
  const source = data.source

  const inSlug = examinationData[slug] || {}
  const inFetus = inSlug[examination_fetus_id] || {}

  return {...examinationData, [slug]: {...inSlug, [examination_fetus_id]: {...inFetus, [source]: data}}}
}

const examinationDataReducer = (examinationData, data) => {
  if(Array.isArray(data)) {
    return data.reduce(singleExaminationDataReducer, examinationData)
  } else {
    return singleExaminationDataReducer(examinationData, data)
  }
}

export const ExaminationContextProvider = withTranslation()(({ t: __, children }) => {
  const history = useHistory();
  const location = useLocation();
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();
  const appContext = useContext(AppContext);
  const liveSessionEventContext = useContext(LiveSessionEventContext);
  const { showNotification, updateNotification, notificationExists } = useContext(NotificationContext)
  const {
    user,
    setUser,
    sameSiteEntities,
    isFeatureFlagEnabled,
    needUserSwitch,
    findEntityInCurrentSiteByDicomUserName,
    addDicomPhysicianNameToUser,
    switchUser,
    config
  } = useAuth();
  const [isExaminationLoaded, setIsExaminationLoaded] = useState(false);
  const [examination, doSetExamination] = useState({});
  const [riskFactorIds, setRiskFactorIds] = useState([]);
  const [instances, setInstances] = useState([])
  const [instanceViews, setInstanceViews] = useState([])
  const [instancePreviewBlobs, setInstancePreviewBlobs] = useState({});
  const [loadedPreviewsList, setLoadedPreviewsList] = useState([]);
  const [draftExams, setDraftExams] = useState([]);
  const [incomingStudiesToIgnore, setIncomingStudiesToIgnore] = useState([]);
  const [popup, setPopup] = useState("");
  const [reassociating, setReassociating] = useState(false);
  const [reassociatingTimedOut, setReassociatingTimedOut] = useState(false);
  const [lastReassociation, setLastReassociation] = useState(false);
  const [changingTrimester, setChangingTrimester] = useState(false);
  const [MALFORMATIONS, setMALFORMATIONS] = useState({});
  const [SYNDROMES, setSYNDROMES] = useState({});
  const [STRUCTURES, setSTRUCTURES] = useState([]);
  const [QUALITYCRITERIA, setQUALITYCRITERIA] = useState([]);
  const [SEMIOLOGYSIGNS, setSEMIOLOGYSIGNS] = useState([]);
  const [medicalHistoryItems, setMedicalHistoryItems] = useState({});
  const [dismissedLiveQuestions, setDismissedLiveQuestions] = useState([]);
  const [showUserSwitchDialog, setShowUserSwitchDialog] = useState(false);
  const [showReassociationDialog, setShowReassociationDialog] = useState(false);
  const [selectExamToStartDialogOpen, setSelectExamToStartDialogOpen] = useState(false);
  const printingTemplate = useMemo(() => config?.printing_configuration?.find((printing_config) => printing_config.trimester === examination.trimester?.toLowerCase()), [examination.trimester, config]);
  const [printingConfig, setPrintingConfig] = useState(false);
  const [instancesToPrint, setInstancesToPrint] = useState([]);
  const [examinationInstanceViews, setExaminationInstanceViews] = useState([]);
  const [share, setShare] = useState(null);
  const [allShares, setAllShares] = useState(null);
  const [neverChangedPreset, setNeverChangedPreset] = useState(true);
  const [includeQRCode, setIncludeQRCode] = useState(false);
  const [exclusivelyQr, setExclusivelyQr] = useState(false);
  const [permissions, setPermissions] = useState({"examination.report.sign": false, "examination.report.unsign": false});
  const [fetusSexVisibility, setFetusSexVisibility] = useState(null);
  const [reviseExamPopupOpen, setReviseExamPopupOpen] = useState(false);
  const [debugTemplate, setDebugTemplate] = useState("");
  const [anonymizeToggle, setAnonymizeToggle] = useState(true);
  const [annotateToggle, setAnnotateToggle] = useState(false);
  const [documentsToggle, setDocumentsToggle] = useState(true);

  const setExamination = (attrs) => {
    doSetExamination(examination => ({...examination, ...attrs})); // Merging to keep associations that may have not been returned
  }

  const episode = examination.episode
  /* 
   * Update the examination's episode
   * Can be used for optimistic update
   */
  const setEpisode = (newEpisode) => {
    if(newEpisode.id != examination.episode_id)
      return console.warn("Can not update an episode not associated with the current examination", examination.episode_id, newEpisode)

    setExamination({episode: {...episode, ...newEpisode}})
  }

  const patient = examination.patient
  /* 
   * Update the examination's patient
   * Can be used for optimistic update
   */
  const setPatient = (newPatient) => {
    if(newPatient.id != examination.patient_id)
      return console.warn("Can not update an patient not associated with the current examination", examination.patient_id, newPatient)

    setExamination({patient: {...patient, ...newPatient}})
  }

  const dating = examination.dating
  /* 
   * Update the selected dating of the examination
   * Can be used for optimistic update
   */
  const setDating = (newDating) => {
    if(newDating.id != examination.dating_id)
      return console.warn("Can not update an dating not associated with the current examination", examination.dating_id, newDating)

    setExamination({dating: newDating})
  }

  /* The examinationData state:
   * {
   *   [slug]: {
   *     [source]: {
   *       [examination_fetus_id | "patient"]: {
   *       }
   *     }
   * }
   */
  const [examinationData, dispatchExaminationData] = useReducer(
    examinationDataReducer,
    {}
  );

  const liveExaminationContext = useContext(LiveExaminationContext);

  useEffect(() => {
    if (examination?.id && appContext.site?.id && liveExaminationContext.examination) setExamination(liveExaminationContext.examination);
  }, [JSON.stringify(liveExaminationContext.examination)]);

  useEffect(() => {
    if (examination?.id && appContext.site?.id && liveExaminationContext.patient) setPatient(liveExaminationContext.patient);
  }, [JSON.stringify(liveExaminationContext.patient)]);

  useEffect(() => {
    if(!examination?.id)
      return false;

    Cookies.set('exam_id', examination.id, {expires: 1});

    ResourceApi.listExaminationData(examination.id)
      .then((r) => {
        dispatchExaminationData(r.data.data)
      })
  }, [examination?.id])

  /* React to examinationData update on the real time examination socket
   * If an examinationData was updated by any one on the examination,
   * the real time examination socket receive an update message containing the updated examinationData
   *
   * The useEffect will be triggered. And so we will dispatch this new ExaminationData in the local
   * examinationData state
  */
  useEffect(() => {
    if (examination?.id && appContext.site?.id && liveExaminationContext.examinationData) dispatchExaminationData(liveExaminationContext.examinationData);
  }, [JSON.stringify(liveExaminationContext.examinationData)])


  useEffect(() => {
    if (examination?.id && liveExaminationContext.incomingDicomInstance) {
      // Update instances
      setInstances(prev => {
        if (prev.find(i => i.id ===  liveExaminationContext.incomingDicomInstance?.id)) {
          return prev.map(p => p.id === liveExaminationContext.incomingDicomInstance?.id ? liveExaminationContext.incomingDicomInstance : p)
        } else {
          return [...prev, liveExaminationContext.incomingDicomInstance]
        }
      });

      // In case the practitioner_id is not set from the backend - populate it
      if (!examination.practitioner_id && !!user?.id) {
        updateExamination({ practitioner_id: user.id });
      }
    }
  }, [liveExaminationContext.incomingDicomInstance]);

  // Handle notifications   
  useEffect(() => {
    // Show notification only for US images (not for SR)
    if (liveExaminationContext.incomingDicomInstance && liveExaminationContext.incomingDicomInstance?.modality === "US") {
      if (!!liveExaminationContext.incomingDicomInstance?.dicom_origin_id) {
        // If there is a dicom_origin_id - it means the image was extracted from a video
        updateNotification(<>{__("examination.incoming.imageFromVideo")}</>, 3000, `instance-${liveExaminationContext.incomingInstance?.id}`);
      }
      else if (isFeatureFlagEnabled("sonio.detect") && examination.trimester !== "ND" && liveExaminationContext.incomingDicomInstance?.dicom_media_type != "video") {
        // If detect is enabled and we are not on an ND exam then show the analyzing notification
        // AND if the incoming media type is NOT a video then error out in 6 seconds (since video processing takes more time + video errors handled separately)

        // Usual case 
        const errorTimeout = 6000;

        // Show analyzing the instance - until we receive the association event
        updateNotification(<><LoaderInline /> {__("examination.incoming.analyzing")}</>, errorTimeout + 1000, `instance-${liveExaminationContext.incomingDicomInstance?.id}`);
      }
      else {
        // In the default case we show received a new image always
        updateNotification(<>{__("examination.incoming.image")}</>, 3000, `instance-${liveExaminationContext.incomingDicomInstance?.id}`);
      }
    }
  }, [liveExaminationContext.incomingDicomInstance]);

  useEffect(() => {
    if (liveSessionEventContext.firstDicomInstanceOfExamination) {
      const { examination_id: examinationId, dicom_instance: dicomInstance } = liveSessionEventContext.firstDicomInstanceOfExamination;

      // Check if user switch necessary
      const performingPhysicianName = dicomInstance.performing_physician_name;
      if (needUserSwitch(performingPhysicianName, dicomInstance)) {
        handleUserSwitch(performingPhysicianName);
      }

      // Handle redirection
      if (!!examination.id && examination.status === "draft" && (!episode?.patient_id || episode?.patient_id === dicomInstance.patient_id)) {
        // If it is a draft that belongs to a patient, make sure we merge in only if the study belongs to the same patient
        mergeDraftExamination(examinationId, examination.id);
        loadDraftExams();
      }
      else {
        history.push(`/exam/${examinationId}`);
      }
    }

    return () => {
      // reset first dicom instance of examination
    }
  }, [liveSessionEventContext.firstDicomInstanceOfExamination]);

  const handleUserSwitch = async (performingPhysicianName) => {
    const performingEntity = findEntityInCurrentSiteByDicomUserName(performingPhysicianName);
    if (performingEntity) {
      const newUser = await switchUser(performingEntity.id);
      showNotification(__('session.currentUser', { currentUserName: newUser.title }), 5000);
    } else {
      setShowUserSwitchDialog(true);
    }
  }

  useEffect(() => { 
    if (!user) return setFetusSexVisibility(null);
    if (!examination?.id) return setFetusSexVisibility(null);
    ResourceApi.getExaminationFetusSexVisibility(examination.id)
      .then(({ data: { fetus_sex_visibility } }) => {
        setFetusSexVisibility(fetus_sex_visibility);
      })
  }, [config?.fetus_sex_visibility, user?.country_id, patient, examination?.id]);

  /**
   * Load malformations and syndromes from db
   */
  useEffect(() => {
    if (!user) return false;

    LookupApi.getMalformation().then((resp) => {
      let allMalformations = {};
      for (const malformation of resp.data.data) {
        allMalformations[malformation.id] = malformation;
      }
      setMALFORMATIONS(allMalformations);
    });

    LookupApi.getSyndrome().then((resp) => {
      let allSyndromes = {};
      for (const syndrome of resp.data.data) {
        allSyndromes[syndrome.id] = syndrome;
      }
      setSYNDROMES(allSyndromes);
    });

    // Load all slides
    LookupApi.listImagePlane().then(resp => {
      let instanceViewMap = [];
      for (const instanceView of (resp.data?.data || [])) {
        instanceViewMap[instanceView.id] = instanceView;
      }
      setInstanceViews(instanceViewMap);
    });

    // Load all medical history items
    LookupApi.getMedicalHistoryItem().then(resp => {
      const mh = {};
      resp.data.data.forEach(item => {
        mh[item.text_id] = item;
      });
      setMedicalHistoryItems(mh);
    });

    LookupApi.getQualityCriteria().then(resp => {
      const qualityCriteria = resp.data?.data || [];
      setQUALITYCRITERIA(qualityCriteria)
    })

    LookupApi.getSemiologySign().then(resp => {
      let allSemiologySigns = {};
      for (const sign of resp.data.data) {
        allSemiologySigns[sign.id] = sign;
      }
      setSEMIOLOGYSIGNS(allSemiologySigns);
    })
  }, [user?.id]);

  const getPredictions = (instance, associatedSlide = false, limit = 3, rowNumber = 0) => {
    let predictions = [];
    if (!instance || !examinationInstanceViews.length) return !!associatedSlide ? [associatedSlide] : predictions;

    const pictureSlides = examinationInstanceViews.filter(slide => slide.type === "picture");
    const otherSlide = examinationInstanceViews.find(instanceView => instanceView?.type === "other");
    const slidesOffset = pictureSlides.filter(slide => !instances.some(instance => instance.slideId === slide.id)).slice(Math.min(rowNumber, pictureSlides.length - limit));
    const instancePredictions = (instance.predictions?.sort((a, b) => b.id - a.id).find(prediction => prediction.type === 'view' && prediction.status === 'done')?.data || []).filter(prediction => prediction?.id !== otherSlide.id);

    if (instancePredictions.length != 0) {
      for (let i = 0; i < instancePredictions.length; i++) {
        const slide = pictureSlides.find(slide => instancePredictions[i]?.id === slide?.id && instancePredictions[i]?.techno === slide?.techno) ?? pictureSlides.find(slide => instancePredictions[i]?.id === slide?.id);
        predictions.push(slide);
      }
    } else {
      for (let i = 0; i < limit; i++) {
        const slide = slidesOffset.find(slide => !!slide && !predictions.some(s => s?.id === slide.id));
        predictions.push(slide);
      }
    }

    if (
      predictions.every(slide => !!slide && slide.id !== associatedSlide?.id)
      && (associatedSlide?.id !== 39 || !!instance.verified)
    ) {
      predictions = [associatedSlide, ...predictions];
    }
    predictions = predictions.filter(s => !!s).slice(0, limit);
    // if (predictions.length < limit) predictions = [...predictions, otherSlide];
    predictions = predictions.fill(false, predictions.length, limit);
    predictions = Array.from({ ...predictions, length: limit })
    return predictions;
  }

  useEffect(() => {
    if (isFeatureFlagEnabled("sonio.detect") && window.localStorage.getItem('zoomUponReceive') !== "on") {
      if (examination.trimester === "ND" && !reassociating) {
        for (const instance of instances) {
          if (instance.modality !== "SR" && !instance.verified && notificationExists(`instance-${instance.id}`)) {
            updateNotification(<>{__("examination.incoming.image")}</>, 3000, `instance-${instance.id}`);
          }
        }
      }
    }
  }, [examination.trimester, instances, reassociating]);

  useEffect(() => {
    if (examination.trimester && examination.trimester !== "ND") {
      setNeverChangedPreset(false);
    }
  }, [examination.trimester]);

  /**
   * Update the association UI based on events received from websocket.
   * TODO: Better handle notification upon receiving a websocket msg
   */
  useEffect(() => {
    if (liveExaminationContext.incomingAssociation) {
      const updatedInstanceMap = {};
      liveExaminationContext.incomingAssociation.forEach((assoc) => {
        updatedInstanceMap[assoc.dicom_instance_id] = assoc;
        const instance = instances.find(instance => instance.id === Number(assoc.dicom_instance_id));

        if (!isNullOrUndefined(instance) && instance.dicom_media_type === "image" && isFeatureFlagEnabled("sonio.detect") && window.localStorage.getItem('zoomUponReceive') !== "on" && instance.modality !== "SR" && !reassociating) {
          if (!!assoc.instance_view_id) {
            if (updatedInstanceMap[instance.id.toString()].verified) {
              if (notificationExists(`instance-${instance.id}`)) {
                if (!!instance?.dicom_origin_id) {
                  updateNotification(<><Icon name="verified" /> {__("examination.incoming.matchedFromVideo", { slideName: instanceViews[Number(assoc.instance_view_id)]?.label[currentLanguage] })}</>, 3000, `instance-${instance.id}`);
                } else {
                  updateNotification(<><Icon name="verified" /> {__("examination.incoming.matched", { slideName: instanceViews[Number(assoc.instance_view_id)]?.label[currentLanguage] })}</>, 3000, `instance-${instance.id}`);
                }
              }
            }
            else if (updatedInstanceMap[instance.id.toString()].source === "error") {
              updateNotification(<><Icon name="close" /> {__("examination.incoming.error")}</>, 3000, `instance-${instance.id}`);
            } else {
              if (notificationExists(`instance-${instance.id}`)) {
                updateNotification(<><Icon name="score-low" /> {__("examination.incoming.notMatched")} <Button size="small" label={__("examination.incoming.openMedia")} variant="outline" onClick={() => history.push(`#media-${instance.id}`)} /></>, 8000, `instance-${instance.id}`);
              }
            }
          }
          else if (assoc.predictions.length > 0 && !instance.verified) {
            updateNotification(<><LoaderInline /> {__("examination.incoming.analyzing")}</>, 7000, `instance-${instance.id}`);
          }
        }
      });
    }
  }, [liveExaminationContext.incomingAssociation, instances, reassociating, currentLanguage, instanceViews]);

  useEffect(() => {
    if (liveExaminationContext.incomingAssociation) {
      const updatedInstanceMap = {};
      liveExaminationContext.incomingAssociation.forEach((assoc) => {
        updatedInstanceMap[assoc.dicom_instance_id] = assoc;
      });

      setInstances((prevInstances) =>
        prevInstances.map((inst) =>
          // Modify the instance association only if it exists in the updates received and also belongs to the same exam
          Object.keys(updatedInstanceMap).includes(inst.id.toString()) &&
            updatedInstanceMap[inst.id.toString()].examination_id ===
            examination.id
            ? {
              ...inst,
              slideId: updatedInstanceMap[inst.id.toString()].instance_view_id,
              idx_in_group: updatedInstanceMap[inst.id.toString()].idx_in_group,
              idx_in_template: updatedInstanceMap[inst.id.toString()].idx_in_template,
              predictions: updatedInstanceMap[inst.id.toString()].predictions,
              source: updatedInstanceMap[inst.id.toString()].source,
              verified: updatedInstanceMap[inst.id.toString()].verified,
              selected_for_print: updatedInstanceMap[inst.id.toString()].selected_for_print,
              shared_with_patient: updatedInstanceMap[inst.id.toString()].shared_with_patient,
              anomaly_override: updatedInstanceMap[inst.id.toString()].anomaly_override,
              anomaly_prediction: updatedInstanceMap[inst.id.toString()].anomaly_prediction,
              qc_prediction: updatedInstanceMap[inst.id.toString()].qc_prediction,
              quality_criteria_override: updatedInstanceMap[inst.id.toString()].quality_criteria_override,
              ve_prediction: updatedInstanceMap[inst.id.toString()].ve_prediction,
              view_evaluation_override: updatedInstanceMap[inst.id.toString()].view_evaluation_override,
              to_retake: updatedInstanceMap[inst.id.toString()].to_retake
            }
            : inst
          )
      );
    }
  }, [liveExaminationContext.incomingAssociation, examination.id, examination.medical_history?.['medicalexam.fetus.sex'], examination.placenta_position_id, medicalHistoryItems, liveExaminationContext.incomingDicomInstance]);

  const mergeDraftExamination = (examinationId, draftExaminationId) => {
    setSelectExamToStartDialogOpen(false);
    return ResourceApi.mergeDraftExamination(examinationId, draftExaminationId)
      .then(_ => { history.push(`/exam/${examinationId}`); })
      .catch(err => {
        if (err.response?.status === 409) {
          showNotification(__("examination.failedToMerge"), 5000)
          setTimeout(() => {
            history.push(`/exam/${examinationId}`)
          }, 1000);
        }
        else showNotification(__("examination.errorOnMerge"), 5000)
      })
  }
  
  useEffect(() => {
    if (examination.status === "completed") {
      setIncomingStudiesToIgnore([]);
      if (location.pathname.match(/^\/exam\/\d+\/dx/)) history.push(`/exam-review/${examination.id}`);
    }
  }, [examination.status])

  const matchUserAndSwitch = async (entityId) => {
    const {dicom_instance: dicomInstance} = liveSessionEventContext.firstDicomInstanceOfExamination;
    
    const updatedUser = await addDicomPhysicianNameToUser(
      entityId,
      dicomInstance.performing_physician_name,
    )

    if (updatedUser.id === user.id) {
      setUser(updatedUser);
    } else {
      await switchUser(entityId);
      showNotification(__('session.currentUser', { currentUserName: updatedUser.title }), 5000);
    }
    setShowUserSwitchDialog(false);
  }

  useEffect(() => {
    if (examination.id && !examination.trimester) examination.trimester = "T2";
    setRiskFactorIds([]);
    setDismissedLiveQuestions([]);
    setIsExaminationLoaded(false);
    setShare(null);
    loadShareForExam();
    setIncludeQRCode(false);
    setExclusivelyQr(false);
    setLastReassociation({});
  }, [examination.id]);

  /*
   * Cancel all previous calls to dx recommendation API 
   */
  useEffect(() => {
    if (user) {
      if (examination?.id && examination.state === 'active') {
        if (!isFeatureFlagEnabled("sonio.dx_v2")) {
          DxAiApi.recommendationCancelCall();
        }
        appContext.setIsLoading(false);
      }
    }
  }, [JSON.stringify({ ...examination, updated_at: '' }), JSON.stringify(user)]);

  const createExamination = async (patientId, dicomStudyId = null, trimester = null, preset = null) => {
    //    const lastExam = [...exams_resp.data.data].pop();
    //    if (!!lastExam && !!patientId) examData.medical_history = lastExam.medical_history;
    setIsExaminationLoaded(false);
    const examData = { dicom_study_id: dicomStudyId, status: "draft", association_status: { status: "active", selected_for_print: true } }
    examData.template_id = appContext.examinationTemplatesConfiguration
      ?.find(templateDef =>
        templateDef.examination_type === "screening" && templateDef.examination_preset_id === (preset || presetsConfig.NDid) && templateDef.position_id === null)
      ?.examination_template_id;

    examData.trimester = trimester || "ND";
    examData.preset_id = preset || presetsConfig.NDid;
    examData.patient_id = patientId;
    const examCreateResponse = await ResourceApi.createExaminationV2(examData);
    const exam = examCreateResponse.data.data;
    /* TODO as the examination is already preloaded may be we can just make a sort
     * of doSetExamiantion that sets examination, patient, dating and might loadInstances
     */
    setExamination(exam);
    await loadExamination(exam.id, true)
    return exam;
  };

  const canCreateAnnonymousExam = () => {
    return true
    //return !(siteFlowsConnectors?.appointment && siteFlowsConnectors?.appointment?.length)
  }

  /**
   * Load an examination by ID into the exam context
   * @param {integer} examId
   * @param {boolean} reload force the reload in case of id = current exam id
   */
  const loadExamination = async (examId, reload = false) => {
    if (examination?.id !== examId || reload) {
      setIsExaminationLoaded(false);
      let respExamination = await ResourceApi.getExamination(examId);
      let exam = respExamination.data.data;

      setInstances([]);

      setExamination(exam);

      await loadInstances(exam);

      // Load examination attachments
      if (isFeatureFlagEnabled("sonio.attachments")) {
        loadExamAssocAttachments(examId);
        loadGeneralExamAssocAttachments();
      }

      // Load permissions for this examination
      Promise.all([ResourceApi.checkExaminationPermission(examId, "examination.report.sign"), ResourceApi.checkExaminationPermission(examId, "examination.report.unsign")]).then(
        ([signPermissionResp, unsignPermissionResp]) => {
          setPermissions({ "examination.report.sign": signPermissionResp.data.allow, "examination.report.unsign": unsignPermissionResp.data.allow });
        }
      );

      setIsExaminationLoaded(true);
    }
  }

  const uploadDocumentList = async ({ siteId, examId, files, addStatusFile, addServerError }) => {
    return new Promise(resolve => {
      const promises = files
        .map(({ file, title })=> {
          let formData = new FormData();
          formData.append('attachment[file]', file);
          formData.append('attachment[title]', title);
          return formData;
        })
        .map((form, index) => ResourceApi.uploadExamDocument(examId, form).then(response => {
          const errors = response?.data?.errors
          if (typeof errors === 'string'){
            if (typeof errors === 'string' || errors instanceof String) { // https://stackoverflow.com/a/9436948
              const translationKey = errors;
              addServerError(translationKey);
            }
          }
          if (errors) return;
          const { id } = response.data.data;
          return ResourceApi.addSharingDocument({
            examId,
            attachmentId: id,
            siteId
          }).then(
            ()=> new Promise((res) => setTimeout(res, 400))
          ).then(
            () => addStatusFile(files[index].title)
          )
      }));
      Promise.all(promises).finally(resolve);
    });
  }

  const updateExamSharedParams = (examinationId, paramKey, paramValue) => {
    ResourceApi.saveExaminationSharedParams(examinationId, {[paramKey]: paramValue});
  }
  const refreshExaminationStatus = async () => {
    let respExamination = await ResourceApi.getExamination(examination.id);
    let exam = respExamination.data.data;
    setExamination(examination => ({...examination, status: exam.status}));
  }

  const refreshDating = async () => {
    let respExamination = await ResourceApi.getExamination(examination.id);
    setExamination(respExamination.data.data);
  }

  /**
   * TODO: not examination context
   * Create a new patient in db
   * @param {object} patient {state, firstName, middleName, lastName, prefix, dob, sex, dicomPatientId, belongsTo, accessionNumber} 
   */
  const createPatient = async (patient) => {
    const newPatient = {
      state: patient.state || "active",
      name: createFullName(patient.lastName?.trim(), patient.middleName?.trim(), patient.firstName?.trim(), patient.prefix?.trim()),
      sex: ["female", "male"].includes(patient.sex) ? patient.sex : "unknown",
      belongs_to: patient.belongsTo || user.id,
      dicom_patient_id: patient.dicomPatientId || "user-" + patient.lastName + Date.now(),
    }
    if (!!patient.dob) newPatient.dob = patient.dob;

    return ResourceApi.createPatient(newPatient).then(resp => {
      return resp.data.data;
    });
  }

  /**
   * Update a new patient in db
   * @param {integer} patientId
   * @param {object} patient {state, firstName, middleName, lastName, prefix, dob, sex, dicomPatientId, belongsTo, accessionNumber} 
   */
  const updatePatient = async (patientId, patientUpdates) => {
    if(patientId == patient.id) {
        setPatient({...patient, ...patientUpdates})
    }
    return ResourceApi.updatePatient(patientId, patientUpdates).then(resp => {
      if (resp.data.data.id === patient.id) setPatient(resp.data.data);
      return true;
    });
  }

  /**
   * Create a new episode in db
   * @param {object} episode {state, patientId, conceptionDate, conceptionDateMethod, conceptionMethod, nbFetuses}
   */
  const createEpisode = async (episode) => {
    if (!episode.patientId) return false;

    const newEpisode = {
      state: episode.state || "active",
      patient_id: episode.patientId,
      conception_date: episode.conceptionDate || "",
      conception_date_method: episode.conceptionDateMethod || "",
      conception_method: episode.conceptionMethod || "",
      nb_fetuses: episode.nbFetuses || 1,
    }
    return ResourceApi.createEpisode(episode.patientId, newEpisode).then(resp => {
      return resp.data.data;
    });
  }

  /**
   * Updates episode in db
  */
  const updateEpisode = async (attrs) => {
    if(examination.frozen)
      return false;
    const oldEpisode = structuredClone(episode);
    setEpisode({...episode, ...attrs})
    return ResourceApi.updateEpisode(patient.id, episode.id, attrs)
      .then(resp => {
        setEpisode(resp.data.data)
        /* TODO remove this when dating related fields
         * are removed from the episode object
         */
        refreshDating()
        return resp.data.data
      })
      .catch(_reason => setEpisode(oldEpisode));
  }

  /**
   * Assign an examination to a episode
   */
  const assignExaminationToEpisode = async (exam, episode_id) => {
    if (!exam.id) return false;
    if( exam.frozen) return false;
    const newExam = { episode_id };
    return ResourceApi.updateExamination(exam.id, newExam).then(resp => {
      setExamination(resp.data.data);
      return resp.data.data;
    })
  }

  /**
   * Create the fetus of a episode
   */
  const createEpisodeFetus = async (params) => {
    return ResourceApi.createFetus(patient.id, episode.id, params)
      .then((resp) => {
        /* The backend automatically creates the examination fetus if required */
        ResourceApi.getExamination(examination.id).then((resp) =>
          setExamination(resp.data.data)
        )
      })
  }

  /**
   * Update the fetus of a episode
   */
  const updateEpisodeFetus = async (fetus, params) => {
    if(examination.frozen)
      return false;
    return ResourceApi.updateFetus(patient.id, episode.id, fetus.id, params)
      .then((resp) => {
        /* The backend automatically creates/deletes the examination fetus if required */
        ResourceApi.getExamination(examination.id).then((resp) =>
          setExamination(resp.data.data)
        )
      })
  }

  /**
   * Remove the fetus from the episode if possible
   */
  const deleteEpisodeFetus = async (fetus) => {
    return ResourceApi.deleteFetus(patient.id, episode.id, fetus.id)
      .then((resp) => {
        /* The backend automatically creates/deletes the examination fetus if required */
        ResourceApi.getExamination(examination.id).then((resp) =>
          setExamination(resp.data.data)
        )
      })
  }

  /**
   * Update the examination data fragment in a optimistic path
   */
  const updateExaminationData = async (data) => {
    const source = "user";
    if(!examination?.id)
      return false;
    if(examination.frozen)
      return false;
    const previousData = getExaminationData(data.slug, data.examination_fetus_id, source);

    dispatchExaminationData({...data, source});
    return ResourceApi.updateExaminationData(examination.id, data.slug, data.examination_fetus_id, data)
      .then((resp) => {
        dispatchExaminationData(resp.data.data);
      })
      .catch(_reason => dispatchExaminationData(previousData));
  }



  /**
   * Get the data based on the slug source and fetus from the examinationData
   *
   * If slug is not provided return all the examinationData
   * If examination_fetus_id is not provided return all the data related to your slug
   * If source is not provided, return all the sources
   *
   * ## Examples
   *  getExaminationData()
   *  {"custom.cardiac.activity": {"12": ...}}
   *
   *  getExaminationData("custom.cardiac.activity")
   *  {"12": {"default": {...}, "user": {...}}}
   *
   *  getExaminationData("custom.cardiac.activity", "12")
   *  {"default": {value: {...}}, "user": {value: {...}}}
   *
   *  getExaminationData("custom.cardiac.activity", "12", "user")
   *  {value: {...}, slected: true, ...}
   */
  const getExaminationData = (slug, selected_examination_fetus_id, source) => {
    if(slug === undefined)
      return examinationData

    const inSlug = examinationData?.[slug]

    if(selected_examination_fetus_id === undefined)
      return inSlug;

    const examination_fetus_id = `${selected_examination_fetus_id}`;
    const inExaminationFetus = inSlug?.[examination_fetus_id];

    if(source === undefined)
      return inExaminationFetus;

    const savedValue = examinationData?.[slug]?.[examination_fetus_id]?.[source];
    if(!savedValue)
      return {
        examination_id: examination.id,
        slug,
        source,
        examination_fetus_id,
        value: null,
        options: null,
        selected: false,
        forwardable: false
      }
    return savedValue;
  }

  window.updateExaminationData = updateExaminationData
  window.getExaminationData = getExaminationData
  window.examinationData = examinationData

  /**
   * Updates the examination object through an optimistic update.
   * Through the optimistic update, the UI seems to be a bit more responsive compared
   * to the previous case where we were waiting for the API call to succeed to change the state.
   */
  const updateExamination = async (fields) => {
    if (!fields.id || fields.id === examination.id) {
      if(examination.frozen)
        return false;
      const oldExam = structuredClone(examination);
      /* 
       * Quick debug caused report to reload too quickly when preset was changed
       */
      const optimisticFields = Object.keys(fields)
        .filter((k) => ["preset_id", "trimester", "template_id"].indexOf(k) == -1)
        .reduce((acc, k) => ({...acc, [k]: fields[k]}), {})
      setExamination(optimisticFields);
      liveExaminationContext.dispatchResourceStates({ resource_type: "examination", data: {...examination, ...optimisticFields}});

      return ResourceApi.updateExamination(examination.id, fields)
        .then(resp => setExamination(resp.data.data))
        .catch(_reason => setExamination(oldExam));
    }
  }

  /**
   * Unfreeze the examination
   */
  const unfreezeExamination = async (id, status) => {
    return ResourceApi.updateExamination(id, {status, frozen: false})
  }

  /**
   * Add or update a stakeholder to the examination
   */
  const associateEntity = async (params, name = "") => {
    /* optimistic update */
    const enrichedParams = {
      ...params,
      entity: {
        id: params.entity_id,
        title: name,
      },
      examination_id: examination.id,
    };
    const entities = [...examination.entities, enrichedParams];
    setExamination({entities});
    
    return ResourceApi.associateEntityToExamination(examination.id, params)
    .then(resp => setExamination(resp.data.data))
  }
  
  const deassociateEntity = async (params) => {
    /* optimistic update */
    const entities = examination.entities.filter(entity => entity.id !== params.id);
    setExamination({entities});

    return ResourceApi.removeEntityFromExamination(examination.id, params)
      .then(resp => setExamination(resp.data.data))
  }

  /*
   * Update a fetus assiociated with an examination
   */
  const updateExaminationFetus = async (fetus, params) => {
    if(examination.frozen)
      return false;
    return ResourceApi.updateExaminationFetus(examination.id, fetus.id, params)
      .then(resp => setExamination(resp.data.data))
  }

  /**
   * Referents / contact points
   */
  const associateContactPoint = async (params, callback) => {
    ResourceApi.associateContactPoint(examination.id, params).then((r) => callback && callback(r?.data?.data));
  }
  
  const updateContactPoint = async (params, association_cp_id, callback) => {
    ResourceApi.updateContactPoint(examination.id, association_cp_id, params).then(() => callback && callback());
  }
  
  const deassociateContactPoint = async (contact_point_id, callback) => {
    ResourceApi.deassociateContactPoint(examination.id, contact_point_id).then(() => callback && callback(contact_point_id));
  }

  /**
   * Submit the report and pass the examination into the next state in the workflow
   */
  const submitReport = async (params) => {
    const promise = ResourceApi.submitReport(examination.id, params)

    promise.then((resp) => {
      setExamination(resp.data.data)
      return resp
    })
    return promise;
  }

  /**
   * Sign the report and pass the examination into the next state in the workflow
   */
  const signReport = async (params) => {
    const promise = ResourceApi.signReport(examination.id, params)

    promise.then((resp) => {
      setExamination(resp.data.data)
      return resp
    })
    return promise;
  }


  /**
   * Updates the examination next slide
   */
  const updateExaminationNextSlide = async (examId, slideId, SlideIdxInTemplate, SlideIdxInGroup) => {
    return ResourceApi
      .updateNextSlide(examId, slideId, SlideIdxInTemplate, SlideIdxInGroup).then(
        resp => {
          if (examId === examination.id) setExamination(exam => ({ ...exam, next_association_view: resp.data.data.next_association_view }));
          return { ...examination, next_association_view: resp.data.data.next_association_view };
        }
      )
  }

  /**
   * Update the medical history values in the app context
   * fields = [{field, raw_value, value = '', tmpValue = ''}]
   * if fields is not array, convert args to the right data structure
   */
  const updateMedicalHistory = (fields, raw_value = null, value = null, tmpValue = null) => {
    if (!Array.isArray(fields)) fields = [{field: fields, raw_value, value, tmpValue}];
    if(examination.frozen)
      return false;

    const medicalHistory = structuredClone(examination?.medical_history || {});
    
    for(let {field, raw_value, value = '', tmpValue = ''} of fields) {

      if (!field) continue;
      if (!field?.options) field.options = [];

      if (raw_value && !value) {
        // associate the raw_value to an option, if possible
        const option = field.options.find(option => {
          const min = option.lower_limit || raw_value;
          const max = option.upper_limit || raw_value + 1;
          return min <= raw_value && max > raw_value;
        });
        if (option) value = option.value;
      }

      const risk_factors = [];
      let is_risky = false;
      if (value) {
        const selectedOption = field.options.find(option => option.value === value);
        if (selectedOption?.risk_factor_id && !risk_factors.includes(selectedOption.risk_factor_id)) risk_factors.push(selectedOption.risk_factor_id);
        if (selectedOption?.is_risky) is_risky = true;
      }

      // collect risk factors from array values (eg: teratogenic risks)
      if (Array.isArray(raw_value)) {
        for (const entry of raw_value) {
          if (!entry.risk_factor_ids) continue;

          for (const risk_id of entry.risk_factor_ids) {
            if (!risk_factors.includes(risk_id)) risk_factors.push(risk_id);
          }
        }
      }

      medicalHistory[field.text_id] = {
        ...field,
        raw_value: raw_value,
        value: value ?? '',
        tmp_value: tmpValue ?? '',
        risk_factors,
        is_risky,
      }
    }

    const oldExam = structuredClone(examination);
    setExamination({medical_history: medicalHistory});
    return ResourceApi.updateExaminationMedicalHistory(examination.id, medicalHistory)
      .then(resp => setExamination(resp.data.data))
      .catch(_reason => setExamination(oldExam));
  }

  const addRiskFactorById = (riskFactorId, raw_value = null, value = '', tmp_value = '') => {
    const item = Object.values(medicalHistoryItems).find(medicalHistoryItem => medicalHistoryItem.id === riskFactorId);
    if (!item || examination?.medical_history?.[item.text_id]) return false;
    updateMedicalHistory(item, raw_value || item?.options_metadata?.default_value || '', value, tmp_value);
  }

  const addMedicationById = (medicationId, riskFactorIds) => {
    let item = examination.medical_history?.["teratogenicrisks.medications"];
    if (!item) {
      const medicalHistoryItem = Object.values(medicalHistoryItems).find(medicalHistoryItem => medicalHistoryItem.text_id === "teratogenicrisks.medications");
      if (medicalHistoryItem) {
        item = {
          id: medicalHistoryItem.id,
          text_id: medicalHistoryItem.text_id,
          value: [],
          raw_value: [],
          risk_factors: [],
        }
      }
    }
    const raw_value = item.value.includes(medicationId) ? item.raw_value : [...item.raw_value, { id: medicationId, risk_factor_ids: riskFactorIds }];
    const value = item.value.includes(medicationId) ? item.value : [...item.value, medicationId];

    updateMedicalHistory(item, raw_value, value);
  }

  /**
   * remove a medication from the list of currently selected medications
   */
  const removeMedicationById = (medicationId) => {
    const newValue = examination.medical_history["teratogenicrisks.medications"].value.filter(id => id !== medicationId);
    const newRawValue = examination.medical_history["teratogenicrisks.medications"].raw_value.filter(risk => risk.id !== medicationId);
    updateMedicalHistory(examination.medical_history["teratogenicrisks.medications"], newRawValue, newValue);
  }

  /**
   * Collect all the risk factor IDs to have quick access
   */
  useEffect(() => {
    if (!examination.medical_history) return false;
    const newRiskFactorIds = Object.values(examination.medical_history).reduce((riskFactors, item) => {
      if (!item.risk_factors?.length) return riskFactors;
      if (Array.isArray(item.raw_value)) return [...riskFactors, ...item.raw_value.reduce((teratogenicRisks, risk) => [...teratogenicRisks, ...(risk.risk_factor_ids || [])], [])];
      return [...riskFactors, ...item.risk_factors];
    }, []);
    setRiskFactorIds(newRiskFactorIds);
  }, [JSON.stringify(examination.medical_history)]);



  /**
   * Remove a field from the medical history
   */
  const removeFromMedicalHistory = (text_id) => {
    if(examination.frozen)
      return false;
    let newExamination = { ...examination };
    if (newExamination.medical_history[text_id])
      delete newExamination.medical_history[text_id];

    updateExamination(newExamination);
  }

  /**
   * To end the examination - also shows the popup if no SR was found
   */
  const endExamination = (force = false) => {
    if (!force && !instances.some(media => media.modality === "SR") && !isFeatureFlagEnabled("sonio.detect")) {
      // no SR received
      setPopup({ message: examination.malformations?.length > 0 ? __("examination.endExaminationDx") : __("examination.endExaminationWithoutSR"), icon: false, cta: (<><Button label={examination.malformations?.length > 0 ? __("examination.OKDx") : __("examination.OK")} onClick={() => setPopup(false)} /> <Button label={examination.malformations?.length ? __("examination.endExamination") : __("examination.endExaminationAnyway")} variant="outline" onClick={() => { setPopup(false); endExamination(true); }} /></>) });
    } else if (!force && isFeatureFlagEnabled("sonio.detect")) {
      // ask to confirm associations
      setPopup({ message: __("examinationReview.endExamConfirmMatching"), icon: false, cta: (<><Button label={__("examinationReview.endExamReview")} variant="outline" onClick={() => setPopup(false)} /> <Button label={__("examinationReview.endExamConfirm")} onClick={() => { setPopup(false); endExamination(true); }} /></>) });
    } else {
      updateExamination({ id: examination.id, status: ExamStatus.COMPLETED }).then(() => {
        setIncomingStudiesToIgnore([]);
        if (location.pathname.match(/^\/exam\/\d+\/dx/)) history.push(`/exam-review/${examination.id}`);
      });
    }
  }
  
  const handleReviseExam = (reason) => {
    setReviseExamPopupOpen(false);

    const status = ExamStatus.REPORT_SIGNED ? ExamStatus.READY_FOR_REVIEW : ExamStatus.INPROGRESS;
    /* unfreezing the examination */
    unfreezeExamination(examination.id, status).then(() => {
      submitReport({
        id: examination.id,
        comment: reason,
        event_type: "comment",
      });
    });
  }

  /**
   * Mark any examination as deleted
   * @param {*} exam
   * @returns a promise to confirm the supression of an exam.
   */
  const deleteExamination = (exam) => updateExamination({ ...exam, state: 'deleted' });

  const deleteDraftExamination = async (exam, onDeleteCallback = () => { }) => {
    setPopup({
      message: __('examination.askForDeleteAction'),
      icon: false,
      cta: (
        <>
          <Button label={__('examination.abortDeleteAction')} variant="outline" onClick={() => setPopup(false)} />
          &nbsp;
          <Button
            label={__('examination.confirmDeleteAction')}
            icon='trash'
            onClick={() => {
              deleteExamination(exam).then(() => {
                if (exam.id === examination.id) setExamination({});
                loadDraftExams();
                onDeleteCallback && onDeleteCallback(exam);
                return true;
              });
              setPopup(false);
            }}
          />
        </>)
    })
  }

  const reloadInstancePreviewBlobList = async () => {
    setInstancePreviewBlobs({});
    await loadInstancePreviewBlobList(instances);
  }

  const loadInstancePreviewBlobList = async (instances) => {
    const previewBlobList = instances
      .filter((i) => i.selected_for_print)
      .map((i) => i.id);
    await downloadInstancePreviewBlobList(previewBlobList); 
  }

  /**
   * Load instances given an exam object (technically only exam id and dicom instance id are required)
   */
  const loadInstances = async (exam) => {
    // Load instances by the study id of the exam (if a study exists) & load associations
    let examInstances = [];
    if (exam && exam.dicom_study_id) {
      const { data: { data: respInstance } } = await ResourceApi.getDicomInstanceByExam(exam.id);
      examInstances = respInstance;
    }

    let { data: { data: associations } } = await ResourceApi.getAssocInstanceByExamId(exam.id);
    let associationsMap = {};

    for (let i = 0; i < associations.length; i++) {
      associationsMap[associations[i].dicom_instance_id] = {}
	    if (!isNullOrUndefined(associations[i]?.instance_view_id))
        associationsMap[associations[i].dicom_instance_id]["slideId"] = associations[i].instance_view_id;
      associationsMap[associations[i].dicom_instance_id]["idx_in_group"] = associations[i].idx_in_group;
      associationsMap[associations[i].dicom_instance_id]["idx_in_template"] = associations[i].idx_in_template;
      associationsMap[associations[i].dicom_instance_id]["verified"] = associations[i].verified;
      associationsMap[associations[i].dicom_instance_id]["source"] = associations[i].source;
      associationsMap[associations[i].dicom_instance_id]["selected_for_print"] = associations[i].selected_for_print;
      associationsMap[associations[i].dicom_instance_id]["shared_with_patient"] = associations[i].shared_with_patient;
      associationsMap[associations[i].dicom_instance_id]["anomaly_override"] = associations[i].anomaly_override;
      associationsMap[associations[i].dicom_instance_id]["anomaly_prediction"] = associations[i].anomaly_prediction;
      associationsMap[associations[i].dicom_instance_id]["qc_prediction"] = associations[i].qc_prediction;
      associationsMap[associations[i].dicom_instance_id]["quality_criteria_override"] = associations[i].quality_criteria_override;
      associationsMap[associations[i].dicom_instance_id]["ve_prediction"] = associations[i].ve_prediction;
      associationsMap[associations[i].dicom_instance_id]["view_evaluation_override"] = associations[i].view_evaluation_override;
      associationsMap[associations[i].dicom_instance_id]["to_retake"] = associations[i].to_retake;
    }

    setInstances(examInstances.filter(inst => inst.modality !== "SR")
      .map(inst =>
        Object.keys(associationsMap).includes(inst.id.toString())
          ? {
            ...inst,
            slideId: associationsMap[inst.id]?.slideId,
            idx_in_group: associationsMap[inst.id]?.idx_in_group,
            idx_in_template: associationsMap[inst.id]?.idx_in_template,
            verified: associationsMap[inst.id].verified,
            source: associationsMap[inst.id].source,
            selected_for_print: associationsMap[inst.id].selected_for_print,
            shared_with_patient: associationsMap[inst.id].shared_with_patient,
            anomaly_override: associationsMap[inst.id].anomaly_override,
            anomaly_prediction: associationsMap[inst.id].anomaly_prediction,
            qc_prediction: associationsMap[inst.id].qc_prediction,
            quality_criteria_override: associationsMap[inst.id].quality_criteria_override,
            ve_prediction: associationsMap[inst.id].ve_prediction,
            view_evaluation_override: associationsMap[inst.id].view_evaluation_override,
            to_retake: associationsMap[inst.id].to_retake
          }
          : inst));
    setIncomingStudiesToIgnore([]);
  }

  // Load the examination template and modify the default template to match it
  const getInstanceViewsForTemplate = useCallback(async (source) => {
    if (!examination.template_id || !instanceViews.length) return [];
    const { data: { data: examinationTemplate } } = await ResourceApi.getExaminationTemplate(examination.template_id, source);
    const instance_views =
      examinationTemplate.configuration.instance_views.map((cfg, index) => ({
        ...instanceViews[Number(cfg.instance_view_id)],
        techno: cfg?.techno ?? "us",
        medias: { [examination.trimester]: cfg.number_of_instances ?? instanceViews[Number(cfg.instance_view_id)].medias[examination.trimester] ?? 1 },
        idx_in_template: index
      }));
    return instance_views;
  }, [examination.template_id, instanceViews]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    getInstanceViewsForTemplate(source).then(instanceViews => setExaminationInstanceViews(instanceViews));
    return () => { source.cancel('Operation canceled. UseEffect cleanup'); };
  }, [getInstanceViewsForTemplate])

  /**
   * PRINTING
   */
  useEffect(() => {
    const f = async () => {
      if (!printingTemplate) {
        setPrintingConfig({});
        return;
      }

      const { data: { data: { blueprint } } } = !!printingTemplate
        ? await ResourceApi.getPrintingTemplate(printingTemplate.printing_template_id)
        : { data: { data: [] } };

      setPrintingConfig({ ...printingTemplate, template: blueprint });
    }
    f()
  }, [printingTemplate?.printing_template_id]);

  useEffect(() => {
    if (!printingConfig?.template) return false;

    let instanceIdsGroupedByPrintableSlide = printingConfig.template.map(pc =>
      instances.filter(instance => pc.instance_view_id === instance.slideId).slice(pc.number_of_instances * -1).map(instance => instance.id)
    );

    setInstancesToPrint(instanceIdsGroupedByPrintableSlide.flat());
  }, [JSON.stringify(printingConfig), instances]);

  const toggleInstanceSelectedForPrinting = async (instanceId, selectedForPrinting = null) => {
    if (!examination.id) return false;
    const instance = instances.find(inst => inst.id === instanceId)
    selectedForPrinting = selectedForPrinting !== null ? selectedForPrinting : !instance?.selected_for_print;
    await ResourceApi.update_printing_properties_association(examination.id, instanceId, selectedForPrinting)
    return true;
  }

  const downloadInstancePreviewBlobList = async (list, results = {}, count = 0) => {
    if (count === 5) {
      return;
    }
    let errors = [];
    setLoadedPreviewsList((prevValues) => [...prevValues, ...list]);
    const promiseList = list.map((item) =>
        fetch(getInstancePreviewUri(item, true)).then((res) => {
          if (res.status === 200) {
            return res.blob();
          }
          return item;
        }).then((blob) => {
          if (typeof blob !== 'number') {
            return [blob, item]
          }
          return item
        }
        )
    );
    const newBlobs = await Promise.all(promiseList).then((blobList) => {
      errors = blobList.filter(blob => typeof blob === 'number');
        return blobList
          .filter(blob => typeof blob !== 'number')
          .reduce(
            (cache, blobObj) => ({
                ...cache,
                [blobObj[1]]: blobObj[0],
            }),
            { ...results }
        )
    });
    if (errors.length === 0 || count === 4) {
      setLoadedPreviewsList([]);
      setInstancePreviewBlobs((prevValue) => ({ ...prevValue, ...newBlobs }));
      return;
    }
    await downloadInstancePreviewBlobList(errors, newBlobs, count + 1);
  };

  const downloadInstancePreviewBlob = async (instanceId) => {
    if (instancePreviewBlobs[instanceId]) {
      return;
    }
    fetch(getInstancePreviewUri(instanceId, true))
      .then((res) => res.blob())
      .then((blob) => {
        setInstancePreviewBlobs(prevValues => ({
          ...prevValues,
          [instanceId]: blob,
        }));
      })
      .then(() => {
        setLoadedPreviewsList(prevValues => (
          prevValues.filter((item) => item !== instanceId)
        ));
      });
    }

  const cleanInstancePreviewBlobs = () => setInstancePreviewBlobs({});

  const toggleInstanceSelectedForSharing = async (instanceId, selectedForSharing = null) => {
    if (!examination.id) return false;
    const instance = instances.find(inst => inst.id === instanceId)
    selectedForSharing = selectedForSharing !== null ? selectedForSharing : !instance?.shared_with_patient;
    await ResourceApi.update_sharing_properties_association(examination.id, instanceId, selectedForSharing)
    return true;
  }

  const deleteInstance = async (instanceId) => {
    ResourceApi.deleteDicomInstance(instanceId);
    setInstances(prevInstances => prevInstances.filter(instance => instance.id !== Number(instanceId)));
  }
  
  useEffect(() => {
    if (liveExaminationContext.removedDicomInstance && instances.some(instance => instance.id === liveExaminationContext.removedDicomInstance?.id)) {
      setInstances(prevInstances => prevInstances.filter(instance => instance.id !== liveExaminationContext.removedDicomInstance?.id));
    }
  }, [liveExaminationContext.removedDicomInstance]);

  const loadDraftExams = () => {
    return ResourceApi.filterExaminations({ type: 'drafts' }).then(resp => {
      setDraftExams(resp.data.data.filter(examination => !!examination.medical_history || !!examination.patient?.id || !!examination.malformations).sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)))
    });
  }

  const reset = () => {
    setExamination({});
    setEpisode({});
    setPatient(null);
    setInstances([]);
    setDraftExams([]);
    setShare(null);
  }

  // Draft exam is an exam where the status is "draft"
  const createDraftExam = (patientId = null, trimester = null, presetId = null, nextUrl = "/exam/") => {
    createExamination(patientId, null, trimester, presetId).then(exam => exam.type === "diagnostic" ? history.push(nextUrl + exam.id + "/dx") : history.push(nextUrl + exam.id));
  }

  /**
   * dismiss a live question
   */
  const dismissLiveQuestion = (riskFactorIds) => {
    if (!Array.isArray(riskFactorIds)) riskFactorIds = [riskFactorIds];
    setDismissedLiveQuestions(dismissedLiveQuestions => [...dismissedLiveQuestions.filter(id => !riskFactorIds.includes(id)), ...riskFactorIds]);
  }

  /**
   * Malformation and syndrome queries
   */
  const getSyndromeById = (syndromeId) => {
    return SYNDROMES[syndromeId];
  };
  const getMalformationById = (malformationId) => {
    if (!MALFORMATIONS[malformationId]) return false;
    return { ...MALFORMATIONS[malformationId], linked_signs: MALFORMATIONS[malformationId].signs };
  };
  const getMalformationBySignId = (signId) => {
    return Object.values(MALFORMATIONS).find(malformation => malformation.signs.includes(signId));
  };

  /**
   * Quality criterias per instance view
   */
  const getQualityCriteriaByInstanceViewId = useCallback((view, instance = false, trimester = examination.trimester, warning_level = null) => {
    if (view?.techno !== "us") return [];
    
    let qualityCriteria = QUALITYCRITERIA.filter(q => isGaInTrimester(q.min_ga, q.max_ga, trimester) && q.instance_views.some(iv => {
      return iv.instance_view_id === view.id && (!warning_level || iv.default_warning_level === warning_level);
    })).map(el => {
      const qcCurrentView = el.instance_views?.find(iv => iv.instance_view_id === view.id);
      return {
        ...el,
        warning_level: qcCurrentView?.default_warning_level,
        is_valid: null,
        is_detected: null,
        source: null,
        score: null,
        status: null,
      }
    });
    // apply predictions
    if (instance) {
      const qcPredictionStatus = instance?.qc_prediction?.status;
      const qcPredictions = instance?.qc_prediction?.data?.[0]?.quality_criterias || [];
      const qcPredictionsOverrides = instance?.quality_criteria_override || [];
      qualityCriteria = qualityCriteria.map(
        qc => {
          const qcPrediction = qcPredictions.find(q => q.id === qc.id);
          const qcPredictionOverride = qcPredictionsOverrides.find(q => q.id === qc.id);
          return qcPredictionOverride
            ? {
              ...qc,
              is_valid: qcPredictionOverride.is_valid,
              is_detected: true,
              source: "user",
              score: 1,
              status: qcPredictionStatus,
            } : {
              ...qc,
              is_valid: qcPrediction?.is_valid,
              is_detected: qc.detectable && isGaInTrimester(qc.min_ga, qc.max_ga, examination.trimester) && !!qcPrediction,
              source: (!isNullOrUndefined(qcPrediction?.score?.normalized_score) ? "vision-ai" : ""),
              score: (qcPrediction?.score?.normalized_score ?? null),
              status: qcPredictionStatus,
            };
        }
      );
    }

    return qualityCriteria;
  }, [QUALITYCRITERIA, examination.trimester]);


  const setTrimester = async (newTrimester) => {
    if(examination.frozen)
      return false;
    await updateExamination({
      id: examination.id,
      trimester: newTrimester
    });
  }

  const setPreset = async (newPresetIdString) => {
    const newPresetId = parseInt(newPresetIdString)
    if (newPresetId === examination.preset_id) return null;
    if (instances.length > 0) {
      setShowReassociationDialog(newPresetId);
    } else {
      await confirmSetPreset(newPresetId);
    }
  }

  const confirmSetPreset = async (newPresetId) => {
    if(examination.frozen)
      return false;

    if (typeof window.OpenReplay !== "undefined") {
      window.OpenReplay.event('preset_changed', {
        preset_id: newPresetId,
        examination_id: examination?.id
      });
    }

    // TODO: Change this to backend
    const newTrimester = appContext.allPresets?.find(preset => preset.id === newPresetId)?.trimester;
    setChangingTrimester(true);
    const newTemplateId = (appContext.examinationTemplatesConfiguration?.find(templateDef =>
      templateDef.examination_type === "screening"
      && templateDef.examination_preset_id === newPresetId
      && templateDef.position_id === examination.fetus_position_id)
      ?? appContext.examinationTemplatesConfiguration?.find(templateDef =>
        templateDef.examination_type === "screening"
        && templateDef.examination_preset_id === newPresetId
        && templateDef.position_id === null))
      ?.examination_template_id;

    await updateExamination({
      preset_id: newPresetId,
      trimester: newTrimester,
      template_id: newTemplateId
    });



    // TODO: move timeout and other stuff inside the reassociation dialog component
    setReassociatingTimedOut(false);
    let timeout = setTimeout(() => {
      setReassociatingTimedOut(true)
    }, 15000);
    ResourceApi.reassociateInstances(examination.id, newTemplateId).then(() => {
      clearTimeout(timeout);
      setReassociatingTimedOut(false);
      setShowReassociationDialog(false);
      setReassociating(false);
      setChangingTrimester(false);
    });
  }

  useEffect(() => {
    if (changingTrimester) {
      if (reassociating) {
        setShowReassociationDialog(true);
      } else {
        setShowReassociationDialog(false);
      }
    }
  }, [reassociating]);


  const setInstanceAssociation = async (slide, instanceId, reloadInstances = false) => {
    setReassociating(true);

    if (slide?.id === null || slide?.id === undefined) return false;
    if (!instanceId) return false;
    const { data: { data: assoc } } = await ResourceApi.upsertAssocInstanceExam({
      examination_id: examination.id,
      dicom_instance_id: instanceId,
      instance_view_id: slide.id,
      idx_in_template: slide.idx_in_template
    });
    if (reloadInstances) await loadInstances(examination);
    setReassociating(false);
    setLastReassociation({
      examination_id: examination.id,
      instanceId,
      instance_view_id: slide.id,
      idx_in_template: slide.idx_in_template,
      idx_in_group: slide.idx_in_group,
      key: slide.key,
    });
    return assoc;
  }

  const loadShareForExam = async () => {
    // Get the shares
    if (examination.id) {
      const { data: { data: allShares } } = await ResourceApi.getExaminationShares(examination.id);
      if (allShares.length > 0) {
        const sh = allShares[0];
        setShare({ ...sh, share_link: sh.share_url ? `${window.location.origin}/${sh.share_url}` : null, instant_share_link: sh.instant_share_url ? `${window.location.origin}/${sh.instant_share_url}` : null })
        setAllShares(allShares);
      }
    }
  }

  const shareExamination = async (annotate, documents, list=[]) => {
    list.map(generalDoc => ResourceApi.addSharingDocument({
      examId: generalDoc.examination_id,
      attachmentId: generalDoc.attachment_id,
      siteId: generalDoc.site_id,
      active: generalDoc.active,
    }))
    // Create a share for this examination
    await ResourceApi.shareExamination(window.location.origin, examination.id, [], annotate, documents);
    await loadShareForExam();
    await loadExamination(examination.id, true);
  }

  const deleteShareForExamination = () => {
    setPopup({
      message: __('examinationReview.askForDeleteShareLink'),
      icon: false,
      cta: (
        <>
          <Button label={__('examination.abortDeleteAction')} variant="outline" onClick={() => setPopup(false)} />
          &nbsp;
          <Button
            label={__('examination.confirmDeleteAction')}
            icon='trash'
            onClick={() => {
              ResourceApi.deleteShareForExamination(examination.id).then(() => {
                setShare(null);
                setPopup(false);
              });
            }}
          />
        </>)
    });
  }
  
  const isCompleted = useCallback(() => {
    return [ExamStatus.COMPLETED, ExamStatus.READY_FOR_REVIEW, ExamStatus.REPORT_SIGNED].includes(examination.status);
  }, [ExamStatus, examination.status]);

  const isLocked = useCallback(() => {
    return examination.frozen;
  }, [examination.frozen]);


  /**
   * Returns true if the exam can be edited, else returns false.
   * IF finished with detect (detect FF + NOT routine FF) - cannot edit
   * IF finished in any other case - can edit until signed frozen/locked
   * IF not finished - can edit in all cases
   */

  const canEdit = useMemo(() => {
    if (isFeatureFlagEnabled("sonio.detect") && !isFeatureFlagEnabled("sonio.routine")) {
      return !isCompleted() && !isLocked();
    }

    if (isLocked()) {
      return false;
    }

    const validStatus = ExamStatus.REPORT_SIGNED !== examination.status;

    const validUser = user.id === examination?.reader_id // I'm the main reader
      || examination?.entities?.some(entity => entity.role === "reading_provider" && entity.entity?.id === user.id) // I'm a secondary reader
      || permissions["examination.report.sign"]; // I can sign

    return validStatus || validUser;
  }, [examination?.status, examination?.reader_id, examination?.entities, user.id, ExamStatus, isFeatureFlagEnabled, isCompleted, permissions["examination.report.sign"]]);
  
  /*
   * Users can submit if the examination is in progress or completed
   * Except if it is a non imaging examination, it can be submitted even in draft
   */
  const canSubmit = useMemo(() => {
    if(ExamStatus.REPORT_SIGNED === examination.status) return false;
    if(ExamStatus.READY_FOR_REVIEW === examination.status) return false;
    if (!examination?.preset?.imaging_expected) return true;
    if (examination.status === ExamStatus.INPROGRESS) return true;
    if (examination.status === ExamStatus.COMPLETED) return true;
    return false;
  }, [examination.status, ExamStatus, examination?.preset?.imaging_expected]);

  /* 
   * Reader or any authorized person can sign except if the report is already signed
   */
  const canSign = useMemo(() => {
    if (ExamStatus.REPORT_SIGNED === examination.status) return false;
    if(!permissions["examination.report.sign"]) return false;
    return true
  }, [examination?.status, examination?.reader_id, examination?.entities, user.id, ExamStatus, permissions["examination.report.sign"], examination?.preset?.imaging_expected]);
  
  const canUnfreeze = useMemo(() => {
    if (isFeatureFlagEnabled("sonio.detect") && !isFeatureFlagEnabled("sonio.routine")) {
      return true;
    }
    
    const validUser = user.id === examination?.reader_id // I'm the main reader
      || examination?.entities?.some(entity => entity.role === "reading_provider" && entity.entity?.id === user.id) // I'm a secondary reader
      || permissions["examination.report.sign"] // I can sign
      || permissions["examination.report.unsign"]; // I can unsign

    return validUser;
  }, [examination?.status, examination?.reader_id, examination?.entities, user.id, ExamStatus, isFeatureFlagEnabled, permissions["examination.report.sign"], permissions["examination.report.unsign"]]);

  return (
    <>
      {!!showReassociationDialog && (
        <ReassociationDialog
          trimester={examination.trimester}
          targetTrimester={showReassociationDialog}
          {...{ confirmSetPreset, setReassociating, reassociating, reassociatingTimedOut, setShowReassociationDialog, setChangingTrimester }}
        />
      )}
      {showUserSwitchDialog && (
        <ManualUserSwitchDialog
          users={sameSiteEntities}
          onUserSelect={matchUserAndSwitch}
          onIgnoreSelect={() => {
            setIncomingStudiesToIgnore((ignoredStudies) => [...ignoredStudies, liveSessionEventContext.firstDicomInstanceOfExamination?.dicom_study_id]);
            setShowUserSwitchDialog(false);
          }}
          currentPhysicianName={liveSessionEventContext.firstDicomInstanceOfExamination?.dicom_instance.performing_physician_name}
        />
      )}
      {!!popup && (<Popup message={popup.message} icon={popup.icon} cta={popup.cta} />)}
      {selectExamToStartDialogOpen &&
        <SelectExamToStartDialog
          close={() => setSelectExamToStartDialogOpen(false)}
          draftExams={draftExams.filter(draft => draft.id !== examination.id && !draft.dicom_study_id)}
          ignoreStudy={(studyId) => {
            setIncomingStudiesToIgnore((ignoredStudyIds) => [...ignoredStudyIds, studyId])
          }}
          mergeExaminations={mergeDraftExamination}
          {...selectExamToStartDialogOpen}
        />
      }
      {reviseExamPopupOpen && <ReviseExamPopup onReviseExam={handleReviseExam} close={() => setReviseExamPopupOpen(false)} />}
      <ExaminationContext.Provider value={{
        MALFORMATIONS,
        SYNDROMES,
        STRUCTURES,
        QUALITYCRITERIA,
        SEMIOLOGYSIGNS,
        medicalHistoryItems,
        getMalformationById,
        getMalformationBySignId,
        getSyndromeById,
        getQualityCriteriaByInstanceViewId,
        isExaminationLoaded,
        examination,
        episode,
        setEpisode,
        assignExaminationToEpisode,
        loadExamination,
        refreshExaminationStatus,
        endExamination,
        reopenExamination: () => canUnfreeze && setReviseExamPopupOpen(true),
        patient,
        dating,
        refreshDating,
        createPatient,
        updatePatient,
        createExamination,
        updateExamination,
        canCreateAnnonymousExam,
        submitReport,
        signReport,
        updateExaminationNextSlide,
        associateEntity,
        deassociateEntity,
        associateContactPoint,
        updateContactPoint,
        deassociateContactPoint,
        updateMedicalHistory,
        addMedicationById,
        addRiskFactorById,
        removeFromMedicalHistory,
        removeMedicationById,
        createEpisode,
        updateEpisode,
        createEpisodeFetus,
        updateEpisodeFetus,
        deleteEpisodeFetus,
        draftExams,
        loadDraftExams,
        createDraftExam,
        deleteDraftExamination,
        incomingStudiesToIgnore,
        setIncomingStudiesToIgnore,
        getInstanceViewsForTemplate,
        cleanInstancePreviewBlobs,
        reloadInstancePreviewBlobList,
        instancePreviewBlobs,
        loadedPreviewsList,
        instanceViews,
        examinationInstanceViews,
        instances,
        loadInstances,
        setInstances,
        setInstanceAssociation,
        anonymizeToggle,
        annotateToggle,
        documentsToggle,
        setAnonymizeToggle,
        setAnnotateToggle,
        setDocumentsToggle,
        deleteInstance,
        instancesToPrint,
        toggleInstanceSelectedForPrinting,
        toggleInstanceSelectedForSharing,
        getPredictions,
        riskFactorIds,
        dismissLiveQuestion,
        dismissedLiveQuestions,
        reset,
        setTrimester,
        setPreset,
        neverChangedPreset,
        shareExamination,
        loadShareForExam,
        deleteShareForExamination,
        share,
        allShares,
        includeQRCode,
        setIncludeQRCode,
        exclusivelyQr,
        setExclusivelyQr,
        isCompleted,
        permissions,
        fetusSexVisibility,
        canEdit,
        canSubmit,
        canSign,
        canUnfreeze,
        debugTemplate,
        setDebugTemplate,
        updateExaminationFetus,
        examinationData,
        updateExaminationData,
        getExaminationData,
        lastReassociation,
        uploadDocumentList,
        updateExamSharedParams,
        pregnancyLengthInDays: config?.pregnancy_length_in_days,
      }}>
        {children}
      </ExaminationContext.Provider>
    </>
  );
});
export const useExamination = () => useContext(ExaminationContext);

ExaminationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
